import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "gatsby-plugin-react-i18next";

const ContainerCookie = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 10;
  font-size: clamp(0.8rem, 2.5vw, 0.9rem);

  & p {
    max-width: 700px;
    width: 100%;
  }
  & a {
    all: unset;
    text-decoration: underline;
    cursor: pointer;
    :hover {
    }
  }
`;

const CustomButton = styled.button`
  all: unset;
  background-color: #001e20;
  padding: 0 20px;
  height: 34px;
  border-radius: 34px;
  max-width: 60px;
  margin-left: 10px;
  :hover {
    background-color: #fbb400;
  }
`;

const WebCookie = () => {
  const [gaTag, setGaTag] = useState(null);
  const { t } = useTranslation();
  const isBrowser = () => typeof window !== "undefined";
  const handleEnable = (e) => {
    e.preventDefault();
    window[`ga-disable-G-QHGZX786GP`] = false;
    window.fbq("consent", "grant");
    window.localStorage.setItem("tagValue", false);
    setGaTag(false);
  };

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    if (window.localStorage.getItem("tagValue") != null) {
      window.localStorage.setItem("tagValue", false);
      setGaTag(false);
    }
  }, []);

  return isBrowser() &&
    window.localStorage.getItem("gtagKunana") == null &&
    gaTag === null ? (
    <ContainerCookie>
      <p>
        {t("cookie.title")}{" "}
        <a href="/policy" target="_blank">
          {t("cookie.more information")}
        </a>
      </p>
      <CustomButton onClick={handleEnable}>{t("cookie.accept")}</CustomButton>
    </ContainerCookie>
  ) : null;
};

export default WebCookie;
